+ <template>
  <div class="app-container">
    <div class="reviewMain" ref='sendRecord' @scroll="scroll">
      <div>
        <div>
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="单位:">
              <el-select v-model="search.institutionId" size="medium" clearable filterable @change="handleInstitution"
                placeholder="请选择单位" class="form-line-item"
                         ref="selectInstitution"
                         @visible-change="isShowSelectOptions"
              >
                <el-option v-for="(item,index) in institutionList" :key='index' :label="item.institutionName"
                  :value="item.institutionId"></el-option>
              </el-select>
              <!-- <el-select v-model="search.institutionId" size="medium" filterable remote reserve-keyword
                placeholder="请输入单位" :remote-method="remoteMethod" class="form-line-item"
                @change="handleInstitution" :loading="selectLoading">
                <el-option v-for="(item,index) in institutionList" :key='index'
                    :label="item.institutionName" :value="item.institutionId"></el-option>
            </el-select> -->
            </el-form-item>
            <el-form-item label="批次:">
              <el-select v-model="search.batchId" size='medium' clearable placeholder="请选择批次" class="form-line-item"
                :disabled='batchShow'
                         ref="selectBatchId"
                         @visible-change="isShowSelectOptions"
              >
                <el-option v-for="(item,index) in batchList" :key='item.batchId' :label="item.batchName"
                  :value="item.batchId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="margin-left:10px;">
              <el-button type="primary" size="mini" icon="el-icon-search" @click="batchSearch">
                搜索
              </el-button>
              <el-button type="success" size="mini" icon="el-icon-refresh" @click="resetHandle">
                重置
              </el-button>
              <el-popover
                  placement="right"
                  width="500"
                  trigger="click">
                <el-table :data="statistics">
                  <el-table-column width="100" property="pendingNum" label="待匹配"></el-table-column>
                  <el-table-column width="100" property="waitingNum" label="待送审"></el-table-column>
                  <el-table-column width="100" property="sendingNum" label="送审中"></el-table-column>
                  <el-table-column width="100" property="reviewNum" label="评审中"></el-table-column>
                  <el-table-column width="100" property="finishedNum" label="已完成"></el-table-column>
                </el-table>
                <el-button type="success" size="mini" style="margin-left: 10px" slot="reference" @click="checkStatistics">勾选批次统计</el-button>
              </el-popover>
            </el-form-item>

          </el-form>

        </div>
      </div>

      <!-- 表格 -->
      <div>
        <!-- <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;" :refresh="{query: refresh}">
          <template #buttons>
            
          </template>
        </vxe-toolbar> -->
        <!-- :loading="loading" -->
        <vxe-table :header-cell-style="headerCellStyle" class="mytable-scrollbar p2p_v2" round align="left"
          ref="paperTable" highlight-current-row highlight-hover-row :keyboard-config="{isArrow: true}"
          :checkbox-config="{trigger: 'cell', highlight: true, range: false}" show-footer :footer-method="footerMethod"
          :merge-footer-items="mergeFooterItems" :loading='loading' :data="paperList" >
          <vxe-column field="id" type='checkbox' width="50" :show-overflow="'tooltip'"></vxe-column>
          <vxe-column field="seq" type='seq' title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
          <vxe-column field="institutionName" title="单位" min-width="260" :show-overflow="'tooltip'" sortable></vxe-column>
          <vxe-column field="batchName" title="批次" min-width="160" :show-overflow="'tooltip'"></vxe-column>
          <vxe-column field="responsiblePerson" title="负责人" width="100" :show-overflow="'tooltip'"></vxe-column>


          <vxe-column field="pendingNum" title="待匹配" width="120" sortable>
            <template #default="{ row }">
<!--              {{row.call-row.c0-row.c1-row.c2-row.c5}}/{{row.call}}-->
              {{row.call - row.waitingNum - row.finishedNum - row.reviewNum - row.sendingNum}}/{{row.call}}
            </template>
          </vxe-column>
          <vxe-column field="waitingNum" title="待送审" width="100" sortable>
            <template #default="{ row }">
<!--              {{row.c0 ? row.c0 : 0}}/{{row.call}}-->
              {{row.waitingNum}}/{{row.call}}
            </template>
          </vxe-column>
          <vxe-column field="sendingNum" title="送审中" width="100" sortable>
            <template #default="{ row }">
<!--              {{row.c1 ? row.c1 : 0}}/{{row.call}}-->
              {{row.sendingNum}}/{{row.call}}
            </template>
          </vxe-column>
          <vxe-column field="reviewNum" title="评审中" width="100" sortable>
            <template #default="{ row }">
<!--              {{row.c2 ? row.c2 : 0}}/{{row.call}}-->
              {{row.reviewNum}}/{{row.call}}
            </template>
          </vxe-column>
          <vxe-column field="finishedNum" title="已完成" width="100" sortable>
            <template #default="{ row }">
<!--              {{row.c5 ? row.c5 : 0}}/{{row.call}}-->
              {{row.finishedNum}}/{{row.call}}
            </template>
          </vxe-column>
          <vxe-column field="endTime" title="交付时间"  width="110" :show-overflow="'tooltip'" sortable></vxe-column>
          <vxe-column field="action" title="操作" width="160" align="center">
            <template #default="{ row }">
              <el-button type="primary" size="mini" @click="handleEnter(row)">进入</el-button>
            </template>
          </vxe-column>
        </vxe-table>
<!--        <span>11111</span>-->
        <vxe-pager perfect align='right' :current-page.sync="page.pageIndex" :page-size.sync="page.pageSize"
          :total="page.total" :page-sizes="page.pageSizes"
          :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
          @page-change="handlePageChange">
          <template #left>
            <vxe-button size="small" @click="firstPage">首页
            </vxe-button>
          </template>
        </vxe-pager>
      </div>
      <!-- 弹窗 -->
      <vxe-modal v-model="editDeadlineModal" :position="{top: '0px'}" width="500" :show-footer='true' show-zoom resize
        :transfer='true'>
        <template #title>
          修改截止日期
        </template>
        <vxe-form :data="editPaper" title-align="right" title-width="150">
          <vxe-form-item title="截止日期：" :span="20" :item-render="{}">
            <vxe-input v-model="editPaper.deadline" placeholder="日期选择" type="date" transfer></vxe-input>
          </vxe-form-item>
        </vxe-form>
        <template #footer>
          <el-button type="primary" size="small" @click='cfmEdit'>确认</el-button>
        </template>
      </vxe-modal>
      <el-dialog :title="sendProcessTitle" :show-close='showClose' :close-on-click-modal='false'
        :close-on-press-escape='false' :visible.sync="sendProcessVis" center width="30%">
        <el-progress :text-inside="true" :stroke-width="24" :percentage="process" status="success"></el-progress>
        <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import {EventBus} from "@/store/event-bus"
  import sendingP2p from '@/api/sendingP2p';
  import {
    sum
  } from 'xe-utils';
  import err401Vue from '@/views/error/err401.vue';
  // import dateFormat from '../../../public/tools/date'

  export default {
    name: 'PaperPage',
    data() {
      return {
        editDeadlineModal: false,
        //数据加载
        loading: false,
        select: '1',
        //搜索条件
        institutionList: [],
        batchList: [],
        search: {
          institutionId: '',
          batchId: '',
          title: '',
          oldId: '',
          paperType: '',
          // author: '',
          state: '',
        },
        batchShow: true,
        //论文列表
        paperList: [
          //     {
          //     paperId: 1,
          //     title: '论文题目',
          //     institutionName: '南京理工大学',
          //     batchName: '2021硕士第一批',
          //     // statue: '送审中',
          //     endTime: '2022-10-10',
          //     waitingNum: 0,
          //     totalReviewNum: 0,
          //     finishedNum: 0,
          //     reviewNum: 0
          // }
        ],
        mergeFooterItems: [{
          row: 0,
          col: 0,
          rowspan: 0,
          colspan: 1
        }],
        //分页
        page: {
          pageIndex: 1,
          pageSize: 10,
          pageCount: 40, //自己用
          pageSizes: [10, 20, 50, 100, 200, 500, {
            label:'全部数据',
            value: 10000
          }],
          total: 100
        },
        //评阅书列表
        receiptList: [{
          expertName: 'sh',
          university: '正高',
          state: '0',
          resultKeywords: '',
          paperKeywords: ''
        }],
        paperKeywords: '',
        //发送进度条
        sendProcessVis: false,
        showClose: false,
        sendProcessTitle: '发送中',
        process: 0,
        sendTimer: '',
        expertLevel: [],
        selectLoading: false,
        // statistics: {
        //   c: 0,
        //   c0: 0,
        //   c1: 0,
        //   c2: 0,
        //   c5: 0,
        //   call: 0
        // },
        statistics: [],
        editPaper: {
          deadline: ''
        }
      }
    },
    mounted() {},
    // activated() {
    //     this.$nextTick(() => {
    //         console.log(this.$store.state.sendRecord.scrollTop);
    //         this.$refs.sendRecord.parentElement.scrollTop = this.$store.state.sendRecord.scrollTop;
    //     })
    // },
    created() {
      EventBus.$on('getNewPaperList',(data)=>{
        this.getP2pPaper();
      });
      window.addEventListener('keydown', this.handleKeyPress);
      this.getInstitution();
      this.getP2pPaper();

    },
    methods: {
        handleKeyPress(event) {
            if (event.keyCode === 13) {
                this.batchSearch();
            }
        },

        // 获取论文列表
      getP2pPaper() {
        this.loading = true;
        let param = new URLSearchParams();

        // param.append("institutionId", this.search.institutionId);
        // param.append("batchId", this.search.batchId);
        let data = {
          "institutionName": null,
          "batchName": null,
          "institutionId": "" + this.search.institutionId,
          "batchId": "" + this.search.batchId,
          "pageNum": this.page.pageIndex,
          "pageSize": this.page.pageSize
        };
        // param.append("data", data);

        console.log(data)
        // console.log(param)
        this.$api.sendingP2p.getAllList_login(data)
          .then(res => {
            if (res.data.code == 200) {
              console.log("paperList",res.data)
              res.data.data.forEach(item =>{
                item["finishedNum"] = item.finishedNum;
                item["reviewNum"] = item.reviewNum;
                item["waitingNum"] = item.waitingNum;
                item["sendingNum"] = item.sendingNum;
                // item["pendingNum"] = item.pendingNum;
                item["pendingNum"] = item.call - item.waitingNum - item.finishedNum - item.reviewNum - item.sendingNum;


              });
              this.paperList = res.data.data;
              this.page.total = res.data.count;
              this.loading = false;
            }
          }).catch(err => {
            this.$message.warning("服务器维护");
          })
      },
      handleEnter(row) {
        // 跳转到 /sending/p2p 页面，传递单位和批次信息
        this.$router.push({
          path: '/sending/p2p',
          query: {
            institutionId: row.institutionId,
            batchId: row.batchId,
          }
        });
      },
      //表格刷新
      refresh() {
        this.getP2pPaper();
      },
      matchHandle() {
        console.log(this.expertLevel);
        //获取已选中的论文
        let selectRecords = this.$refs.paperTable.getCheckboxRecords();
        if (selectRecords.length == 0) {
          this.$message.warning("请选择论文");
          return;
        }
        const loading = this.$loading({
          lock: true,
          text: '正在匹配',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let params = {
          matchType: 'out',
          expertLevel: this.expertLevel.join(','),
          resultIds: null
        };
        let idInfos = [];
        selectRecords.forEach((item) => {
          idInfos.push({
            paperId: item.paperId
          });
        })
        params["idInfos"] = idInfos;
        this.$api.sendingP2p.getMatch(params)
          .then(res => {
            loading.close();
            if (res.data.code == 200) {
              this.$message.success("匹配成功！");
            } else {
              this.$message.warning("匹配失败!");
            }
          }).catch(err => {
            loading.close();
            this.$message.warning("服务器维护！");
          })
      },
      // 导出送审记录
      exportSending() {
        let selectRecords = this.$refs.paperTable.getCheckboxRecords();
        if (selectRecords.length == 0) {
          this.$message.warning("请选择论文");
          return;
        }
        const loading = this.$loading({
          lock: true,
          text: '导出中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let idInfos = [];
        selectRecords.forEach((item) => {
          idInfos.push(item.paperId);
        })
        let params = {
          paperIds: idInfos.join(',')
        }
        this.$api.sendingP2p.generateSendingTable(params)
          .then(res => {
            if (res.data.code == 200) {
              let p = {
                path: res.data.data
              }
              this.$api.reviewCheck.getTables(p)
                .then(res1 => {
                  const blob = new Blob([res1.data], {
                    type: 'Application/vnd.ms-excel;charset=utf-8'
                  });
                  let url = window.URL || window.webkitURL;
                  const href = url.createObjectURL(blob);
                  let downloadElement = document.createElement('a');
                  downloadElement.href = href;
                  downloadElement.download = this.getFileName(res.data.data); //下载后文件名
                  document.body.appendChild(downloadElement);
                  downloadElement.click(); //点击下载
                  document.body.removeChild(downloadElement); //下载完成移除元素
                  window.URL.revokeObjectURL(href); //释放掉blob对象
                  loading.close();
                }).catch(err => {
                  this.$message.warning("导出excel错误！");
                })
            }
          })
          .catch(err => {
            this.$message.warning("导出评审记录错误");
          })
      },
      getFileName(str) {
        return str.substr(str.indexOf('\\') + 1)
      },
      checkStatistics(){
        // 得到所有checkbox
        this.statistics = {
          pendingNum: 0,
          waitingNum: 0,
          sendingNum: 0,
          reviewNum: 0,
          finishedNum: 0,
            call: 0
        };
        // console.log(this.$refs.myTable.getCheckboxRecords())
        let checkBox = this.$refs.paperTable.getCheckboxRecords();
        console.log(checkBox)
        for(let i=0;i<checkBox.length;i++){
          this.statistics.waitingNum = this.statistics.waitingNum + (checkBox[i].waitingNum);
          this.statistics.sendingNum = this.statistics.sendingNum + (checkBox[i].sendingNum);
          this.statistics.reviewNum = this.statistics.reviewNum + (checkBox[i].reviewNum);
          this.statistics.finishedNum = this.statistics.finishedNum + (checkBox[i].finishedNum);
          this.statistics.call = this.statistics.call + (checkBox[i].call);
        }
        this.statistics.c = this.statistics.call-this.statistics.waitingNum-this.statistics.sendingNum-this.statistics.reviewNum-this.statistics.finishedNum

        // this.statistics.c = this.statistics.c + "/" + this.statistics.call
        // this.statistics.c0 = this.statistics.c0 + "/" + this.statistics.call
        // this.statistics.c1 = this.statistics.c1 + "/" + this.statistics.call
        // this.statistics.c2 = this.statistics.c2 + "/" + this.statistics.call
        // this.statistics.c5 = this.statistics.c5 + "/" + this.statistics.call

        let pendingNum = this.statistics.pendingNum + "/" + this.statistics.call
        let waitingNum = this.statistics.waitingNum + "/" + this.statistics.call
        let sendingNum = this.statistics.sendingNum + "/" + this.statistics.call
        let reviewNum = this.statistics.reviewNum + "/" + this.statistics.call
        let finishedNum = this.statistics.finishedNum + "/" + this.statistics.call

        this.statistics = [{
          pendingNum:pendingNum,
          waitingNum:waitingNum,
          sendingNum:sendingNum,
          reviewNum:reviewNum,
          finishedNum:finishedNum
        }]

        console.log(this.statistics)
      },
      //获取送审单位
      getInstitution() {
        this.$api.schoolCustom.getSendingInstitution()
          .then(res => {
            console.log(res.data.data)
            this.institutionList = res.data.data;
            this.selectLoading = false;
          })
          .catch(err => {
            this.$message.warning('接口错误');
          })
      },
      //获取送审单位的批次
      getBatch(institutionId) {
        let param = new URLSearchParams();
        param.append("institutionId", institutionId)
        this.$api.sendingP2p.getBatch(param)
          .then(res => {
            console.log(res.data.data);
            this.batchList = res.data.data;
          })
          .catch(err => {
            this.$message.warning("服务器维护");
          });
      },
      //机构选择改变
      handleInstitution(val) {
        console.log(val)
        this.search.batchId = '';
        if (val == '') {
          this.batchShow = true;
          return;
        }
        this.batchShow = false;
        this.getBatch(val);
      },
      //查看单篇论文的送审情况
      handleSingleCondition(paperId, batchId, paperName) {
        this.$router.push({
          name: 'sendRecord',
          params: {
            id: paperId
          }
        });
      },
      // 下载论文原件
      downloadPaper() {
        let selectRecords = this.$refs.paperTable.getCheckboxRecords();
        if (selectRecords.length <= 0) {
          this.$message.warning('请选择论文！');
          return;
        }
      },
      // 修改截止时间
      editDeadline() {
        let selectRecords = this.$refs.paperTable.getCheckboxRecords();
        if (selectRecords.length <= 0) {
          this.$message.warning('请选择论文！');
          return;
        }
        this.editDeadlineModal = true;
      },
      cfmEdit() {
        console.log(this.editPaper);
        if (this.editPaper.deadline == '') {
          this.$message.warning("请选择截止日期");
          return;
        }
        let selectRecords = this.$refs.paperTable.getCheckboxRecords();
        let paperIds = [];
        selectRecords.forEach((item) => {
          paperIds.push(item.paperId);
        });
        let params = {
          paperIds: paperIds,
          endTime: this.dateFormat(this.editPaper.deadline)
        }
        this.$api.sendingP2p.postModifyPaperEndTime(params)
          .then(res => {
            if (res.data.data == 200) {
              this.$message.success("修改成功");
              this.editDeadlineModal = false;
            }
          }).catch(err => {
            this.$message.warning("修改截止日期错误");
          })
      },
      dateFormat(time) {
        var datetime = new Date(time);
        // datetime.setTime(time);
        var year = datetime.getFullYear();
        var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() +
          1;
        var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
        return year + "-" + month + "-" + date;
      },
      // 发送催审邮件
      sendUrgeMail() {
        //获取已选中的论文
        let selectRecords = this.$refs.paperTable.getCheckboxRecords();
        if (selectRecords.length <= 0) {
          this.$message.warning('请选择论文！');
          return;
        }
        const loading = this.$loading({
          lock: true,
          text: '正在发送',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let paperIds = [];
        selectRecords.forEach((item) => {
          paperIds.push(item.paperId);
        });
        let param = {
          paperIds: paperIds.join(','),
        };

        this.$api.sendingP2p.sendUrgeMail(param)
          .then(res => {
            loading.close();
            if (res.data.code == 200) {
              this.$message.success("发送成功");
            } else {
              this.$message.warning("发送失败");
            }
          }).catch(err => {
            this.$message.warning("发送失败");
          })
      },
      //发送邀审信息
      sendEmailHandle(paperId) {
        //获取已选中的论文
        let selectRecords = this.$refs.paperTable.getCheckboxRecords();
        if (selectRecords.length <= 0) {
          this.$message.warning('请选择论文！');
          return;
        }
        // this.process = 0;
        // this.sendProcessVis = true;
        const loading = this.$loading({
          lock: true,
          text: '正在发送',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let paperIds = [];
        selectRecords.forEach((item) => {
          paperIds.push(item.paperId);
        });
        let param = {
          paperIds: paperIds.join(','),
        };

        this.$api.sendingP2p.getSendEmail(param)
          .then(res => {
            loading.close();
            // this.showClose = true;
            if (res.data.code == 200) {
              // this.process = 100;
              // this.sendProcessTitle = '发送成功';
              this.$message.success("发送成功");
            } else {
              // this.process = 99;
              // this.sendProcessTitle = '发送失败';
              this.$message.warning("发送失败");
            }
          }).catch(err => {
            this.$message.warning("发送失败");
          })

        // this.$axios.post("/sending_paper/send_email", param)
        //     .then(res => {
        //         // clearInteral(this.sendTimer);
        //         loading.close();
        //         this.showClose = true;
        //         if (res.data.code == 1) {
        //             this.process = 100;
        //             this.sendProcessTitle = '发送成功';
        //             this.$message.success("发送成功");
        //         } else {
        //             this.process = 99;
        //             this.sendProcessTitle = '发送失败';
        //             this.$message.warning("发送失败");
        //         }
        //     })
        //     .catch(err => {
        //         loading.close();
        //         this.$message.warning("服务器维护！");
        //     });
      },
      //获取发送精度
      getProcess() {
        this.$axios.get('sending_paper/progress')
          .then(res => {
            this.process = res.data.data;
          }).catch(err => {
            this.$message.warning("服务器维护");
          })
      },
      batchSearch() {
        this.page.pageIndex = 1;
        this.getP2pPaper();
      },
      resetHandle() {
        this.search = {
          institutionId: '',
          batchId: '',
        };
      },
      //分页操作
      handlePageChange({
        currentPage,
        pageSize
      }) {
        this.page.pageIndex = currentPage;
        this.page.pageSize = pageSize;
        this.getP2pPaper();
      },
      firstPage() {
        this.page.pageIndex = 1;
        this.getP2pPaper();
      },
      endPage() {
        this.page.pageIndex = parseInt(this.page.total / this.page.pageSize) + (this.page.total % this.page
          .pageSize == 0 ? 0 : 1);
        this.getP2pPaper();
      },
      //获取选中的论文
      getSelectEvent() {
        let selectRecords = this.$refs.paperTable.getCheckboxRecords();

        console.log(selectRecords);
      },
      footerMethod({
        columns,
        data
      }) {
        console.log(columns);
        console.log(data);
        const sums = [];
        let finishedNum = 0,
          reviewNum = 0,
          waitingNum = 0,
          expertNumber = 0,
          pendingNum = 0,
          sendingNum = 0;
        data.forEach(item => {
          finishedNum += item.finishedNum; //已完成
          reviewNum += item.reviewNum; //评审中
          waitingNum += item.waitingNum; //待送审
          sendingNum += item.sendingNum; //送审中
          pendingNum += (item.call - item.finishedNum - item.reviewNum - item.waitingNum - item.sendingNum); //待匹配
          expertNumber += item.call; //call
          // finishedNum += item.finishedNum;
          // reviewNum += item.reviewNum;
          // waitingNum += item.waitingNum;
          // sendingNum += item.sendingNum;
          // pendingNum += item.pendingNum;
          // expertNumber += item.expertNumber;
        })
        columns.forEach((column, columnIndex) => {
          if (columnIndex === 0) {
            sums.push('总计')
          } else {
            if (column.property == undefined) {
              console.log("!!!!!");
              // console.log(sums);
              sums.push('-');
            } else {
              console.log(column.property)

              switch (column.property) {

                case 'waitingNum':
                  sums.push(waitingNum + '/' + expertNumber);
                  break;
                case 'sendingNum':
                  sums.push(sendingNum + '/' + expertNumber);
                  break;
                case 'reviewNum':
                  sums.push(reviewNum + '/' + expertNumber);
                  break;
                case 'finishedNum':
                  sums.push(finishedNum + '/' + expertNumber);
                  break;
                case 'pendingNum':
                  sums.push(pendingNum + '/' + expertNumber);
                  // console.log(sums)
                  break;
                default:
                  sums.push('-');
                  console.log(sums);
              }

            }
          }
        })
        // 返回一个二维数组的表尾合计
        // console.log(sums)
        this.$forceUpdate();
        return [sums]
      },
      headerCellClassName({
        column,
        columnIndex
      }) {
        return 'vxe-table-thead'
      },
      headerCellStyle({
        row,
        column,
        rowIndex,
        columnIndex
      }) {
        return "background-color:#e0e0e0f1"
      },
      scroll(e) {
        console.log('scroll', e.target.scrollTop)
      },
        isShowSelectOptions(isShowSelectOptions){
            if(!isShowSelectOptions) this.$refs.selectInstitution.blur();
            if(!isShowSelectOptions) this.$refs.selectBatchId.blur();
        },

    },
    destroyed() {
      clearInterval(this.timer);
    },
    //在页面离开时记录滚动位置
    beforeRouteLeave(to, from, next) {
      this.$store.dispatch('changeScrollTop', this.$refs.sendRecord.parentElement.scrollTop);
      next()
    },
    //进入该页面时，用之前保存的滚动位置赋值
    // beforeRouteEnter(to, from, next) {
    //     next(vm => {
    //         console.log(vm.scrollTop);
    //         console.log(vm.$refs)
    //     })
    // },
    components: {

    }
  }
</script>

<style scoped>
  .inputSelect {
    width: 120px;
  }

  .collapseInput {
    margin-top: 20px;
  }

  .searchBtn {
    padding: 20px 0px 0px 0px;
    text-align: center;
  }

  .box-card {
    padding-bottom: 0px !important;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .form-line-item {
    width: 15em;
  }

  .el-form-item {
    margin-bottom: 10px !important;
  }
</style>
<style>
  .p2p_v2 .vxe-table--body-wrapper {
    height: calc(100vh - 285px);
  }

  .dialog .el-dialog {
    margin-top: 5vh !important;
    border-radius: 15px;
  }

  .searchForm .el-form-item__label {
    background-color: #F5F7FA;
    color: #909399;
  }
</style>